import { IColumnClickConfig, IColumnRenderConfig, Info, TableList, TextInput, ToggleSwitch, ToggleSwitchLabelLocationEnum } from '@Wonder-Cave/ui';
import { Filter } from '@Wonder-Cave/ui/dist/Table/Filters';
import { SearchIcon } from '@heroicons/react/outline';
import { TcrCampaignStage } from '@shared/enums';
import { Auth0Role, FilterDataTypeEnum, FilterOperatorEnum, ISearchClientsRecord, ISearchFilter, ISearchPagination, ISearchRequest } from '@shared/models';
import { format } from 'date-fns';
import { uniq } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useNotifications } from '../../contexts/NotificationContext';
import useClients from '../../hooks/useClients';
import useRoles from '../../hooks/useRoles';
import useTenants from '../../hooks/useTenants';
import { Button } from '../shared/Buttons/Button';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import { Card } from '../shared/Card';
import { IDropdownValue } from '../shared/Form/Dropdown';
import GenericDropdown from '../shared/Form/Dropdowns/GenericDropdown';
import { Pagination } from '../shared/Table/Pagination';
import ProviderDropdown from './Dropdowns/ProviderDropdown';
import { clientTableTake, defaultClientTableOptions, getColumns } from './types';

const Clients = () => {
  const [clientLoading, setClientLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedItem, setSelectedItem] = useState<ISearchClientsRecord>();
  const [showDetailsPanel, setShowDetailsPanel] = useState(false);
  const [tableOptions, setTableOptions] = useState(defaultClientTableOptions);
  const { addNotification } = useNotifications();

  const allowProviderUpdated = useRoles([Auth0Role.GSD_ADMIN]);
  const allowDefaultClickerGroup = useRoles([Auth0Role.GSD_ADMIN]);
  const allowClientEdit = useRoles([Auth0Role.GSD_ADMIN]);
  const navigate = useNavigate();

  const [{ data: clientSearchResponse, loading, error }, refetch] = useClients(tableOptions);
  // Create client hook
  // Update client hook

  const [{ data: tenants, loading: tenantsLoading }] = useTenants();
  const tenantOptions: IDropdownValue[] = tenants?.map(t => ({ label: `${t.name} (${t.environment.id})`, value: t.orgId, additionalData: { environment: t.environment.name, tenantId: t.id } })) ?? [];

  const clients = clientSearchResponse?.records ?? [];
  const totalCount = clientSearchResponse?.totalCount ?? 0;

  const columnClickConfig: IColumnClickConfig = {
    name: (item: ISearchClientsRecord) => navigate(`/clients/${item.id}`, { state: { environment: item.environment } }),
  };

  const columnRenderConfig: IColumnRenderConfig = {
    name: (item: ISearchClientsRecord) =>
      <div className="flex flex-col">
        <h5 className="text-gray-500 uppercase">
          {item?.tenant?.name ?? 'N/A'}
        </h5>
        <div>
          {item?.name}
        </div>
      </div>,
    provider: (item: ISearchClientsRecord) =>
      <div>{uniq(item?.providers?.map(p => p.name)).join(', ')}</div>,
    isActive: (item: ISearchClientsRecord) =>
      <ToggleSwitch
        checked={!!item?.isActive}
        onChange={() => { }}
        labelLocation={ToggleSwitchLabelLocationEnum.RIGHT}
        checkedColor='bg-turquoise'
        uncheckedColor='bg-light-gray'
      />,
    tcrStage: (item: ISearchClientsRecord) => {
      switch (item?.tcrStage) {
        case TcrCampaignStage.CAMPAIGN_ADD:
          return <span title="Campaign Add" className="select-none">
            <img id="logo" className="w-auto h-21" src={`/StatusLightYellow.svg`} alt="Pending" />
          </span>;
        case TcrCampaignStage.CAMPAIGN_SHARE_ACCEPT:
          return <span title="Campaign Share Accept" className="select-none">
            <img id="logo" className="w-auto h-21" src={`/StatusLightYellow.svg`} alt="Pending" />
          </span>;
        case TcrCampaignStage.CAMPAIGN_DCA_COMPLETE:
          return <span title="Campaign DCA Complete" className="select-none">
            <img id="logo" className="w-auto h-21" src={`/StatusLightGreen.svg`} alt="Accepted" />
          </span>;
        case TcrCampaignStage.CAMPAIGN_SHARE_DELETE:
          return <span title="Campaign Share Deleted" className="select-none">
            <img id="logo" className="w-auto h-21" src={`/StatusLightRed.svg`} alt="Rejected" />
          </span >;
        default:
          return <span title="Unknown" className="select-none">
            N/A
          </span>;
      }
    },
    providers: (item: ISearchClientsRecord) => {
      const providerCache = {};
      item.tcr_campaigns?.forEach((campaign) => {
        if ((campaign.system_numbers_present && campaign.isActive) && !providerCache[campaign.provider.name]) {
          providerCache[campaign.provider.name] = [campaign.campaignType];
        }
        else if ((campaign.system_numbers_present && campaign.isActive) && providerCache[campaign.provider.name])
          providerCache[campaign.provider.name ?? 'test'].push(campaign.campaignType);
      });

      return (Object.keys(providerCache).length > 0 ? <div className="flex flex-inline">
        <div className='mr-1'>
          {Object.keys(providerCache).toString().replaceAll(',', ', ')}
        </div>
        <Info text={<div>
          <h1 className="text-lg text-bold">Campaign Types</h1>
          <div className="flex flex-wrap">
            {Object.keys(providerCache).map((key) => {
              const campaignTypeSet = new Set(providerCache[key]);
              return (<div className={Object.keys(providerCache).length !== 2 ? "w-1/2 pr-4" : "w-1/2 pr-8"}>
                <h4 className="mt-2 text-bold">{key}</h4>
                {Array.from(campaignTypeSet).map((campaignType) => <p className="text-xs mt-1">{campaignType}</p>)}
              </div>);
            })}
          </div>
        </div>} className='ml-1 mt-1' />
      </div> : <div>N/A</div>);
    }
  };

  // 

  const columns = getColumns(columnClickConfig, columnRenderConfig);

  const handleSearchOptionChange = (searchOptions: ISearchRequest) => {
    setTableOptions(searchOptions);
  };

  const handleRefetch = async () => {
    try {
      await refetch();
    } catch (err) { }
  };

  useEffect(() => {
    const searchText = tableOptions.filters.find((filter) => filter.fieldName === 'name')?.value;

    handleRefetch();

    setSearchText(searchText ?? '');
  }, [tableOptions]);

  const handleSearch = () => {
    let searchFilter = {
      dataType: FilterDataTypeEnum.STRING,
      fieldName: 'name',
      operator: FilterOperatorEnum.CONTAINS,
      value: searchText,
    };

    const newSearchOptions = { ...tableOptions };

    newSearchOptions.filters = newSearchOptions.filters.filter((filter) => filter.fieldName !== 'name');

    newSearchOptions.filters.push(searchFilter);

    newSearchOptions.pagination = defaultClientTableOptions.pagination;

    setTableOptions(newSearchOptions);
  };

  const handleClear = () => {
    const newSearchOptions = { ...tableOptions };

    newSearchOptions.filters = newSearchOptions.filters.filter((filter) => filter.fieldName !== 'name');

    setTableOptions(newSearchOptions);
  };

  const closeDetailsPanel = () => {
    setShowDetailsPanel(false);
    setSelectedItem(undefined);
  };

  const handlePagination = (newPaginationConfig: ISearchPagination) => {
    const newSearchOptions = { ...tableOptions };
    newSearchOptions.pagination = newPaginationConfig;

    handleSearchOptionChange(newSearchOptions);
  };

  const handlePageNumber = (page: number) => {
    const newSearchOptions = { ...tableOptions };
    newSearchOptions.pagination.skip = page * clientTableTake;
    newSearchOptions.pagination.take = clientTableTake;

    handleSearchOptionChange(newSearchOptions);
  };

  // TODO: Determine how to handle this on P2P

  // const setDefaultClickerGroup = async (client?: IClientEntity, clickerGroupId?: string) => {
  //   const defaultClickerGroupId = (clickerGroupId?.length ?? 0) > 0 ? clickerGroupId : null;

  //   if (allowDefaultClickerGroup && client?.id) {
  //     const request: ISetDefaultClickerGroupRequest = {
  //       clientId: client.id,
  //       defaultClickerGroupId,
  //     };

  //     await axiosPost<IHttpResponse<void>>('/clicker-groups-set-default', request);
  //   }
  // };

  return (
    <>
      <div className="flex pb-2 px-28">
        <h1 id="clients-title" className="flex justify-between pb-2">
          Clients
        </h1>
        <div className="flex ml-auto">
          <TextInput
            id="nameLookup"
            name="nameLookup"
            placeholder="Name"
            search
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            onClear={handleClear}
            onEnter={handleSearch}
            leadingIcon={<SearchIcon />}
            clearable
          />
          <Button
            id="add-client"
            variant={ButtonVariantEnum.SECONDARY}
            disabled={!allowClientEdit}
            className='ml-4'
            onClick={() => {
              navigate('/clients/add');
            }}
          >
            Add Client
          </Button>
        </div>
      </div>

      <div className='pb-5'>
        <TableList
          loading={loading}
          shimmer
          columns={columns}
          items={clients}
          totalCount={totalCount}
          limit={clientTableTake}
          paginate
          onPaginate={handlePageNumber}
          actions={[
            {
              label: 'Edit',
              action: (item: ISearchClientsRecord) => navigate(`/clients/${item?.id}/edit`)
            }
          ]}
          cssOverride={{
            'table-list-header': `!pl-4`
          }}
          filters={[{
            label: 'Provider',
            id: 'providerName',
            dataType: FilterDataTypeEnum.STRING,
            operator: FilterOperatorEnum.EQUALS,
            customDropdown(value, onChange) {
              return <ProviderDropdown
                onBlur={() => { }}
                value={value?.value}
                onChange={(newValue) => { onChange(newValue as IDropdownValue); }} />;
            },
          }, {
            label: 'Tenant',
            id: 'authId',
            dataType: FilterDataTypeEnum.STRING,
            operator: FilterOperatorEnum.EQUALS,
            customDropdown: (value, onChange) => {
              return <GenericDropdown
                placeholder='Search for a tenant'
                label=''
                options={tenantOptions}
                value={value}
                onChange={(newValue) => {
                  onChange(newValue as IDropdownValue);
                }}
              />;
            }
          },
          {
            label: 'Client Status',
            id: 'isActive',
            dataType: FilterDataTypeEnum.BOOLEAN,
            operator: FilterOperatorEnum.EQUALS,
            customDropdown: (value, onChange) => {
              return <GenericDropdown
                placeholder='Select a status'
                label='Status'
                options={[{ label: 'Enabled', value: true }, { label: 'Disabled', value: false }]}
                value={value}
                onChange={(newValue) => {
                  onChange(newValue as IDropdownValue);
                }}
              />;
            }
          }
          ]}
          onFilter={(filters: Filter[]) => {
            if (filters.length > 0) {
              console.log('filters', filters);
              const formattedFilters: ISearchFilter[] = filters.map(f => {
                return [{
                  fieldName: f.id,
                  dataType: f.dataType as FilterDataTypeEnum,
                  operator: f.operator,
                  value: f.id === 'providerName' ? f.selectedOption?.label : f.selectedOption?.value // only connection btw providers btw GR and GSD is the name so leveraging lable rather then value for this filter value
                }];
              })
                .flat();

              const searchTextFilter = tableOptions.filters.find((option) => option.fieldName === 'name');
              if (searchTextFilter) formattedFilters.push(searchTextFilter);
              setTableOptions((prevState) => ({
                ...prevState,
                filters: [
                  ...formattedFilters
                ]
              }));
            } else {
              const searchTextFilter = tableOptions.filters.find((option) => option.fieldName === 'name');
              let updatedTableOptions = defaultClientTableOptions;
              if (searchTextFilter) updatedTableOptions = { ...updatedTableOptions, filters: [searchTextFilter] };
              setTableOptions(updatedTableOptions);
            }
          }}
        />
      </div>

      <ul role="list" className="flex flex-col mt-4 space-y-4 md:hidden">
        {clients?.map((client) => (
          <Card
            key={client.id}
            header={client.name}
            actionOnHeaderClick
            padded={false}
          >
            <div className="flex-1 px-3 py-3 space-y-4 truncate">
              <div className="flex flex-row justify-between mt-1 text-sm text-gray-500">
                <div className="flex flex-col">
                  <label className="font-semibold">Status: </label>
                  {allowProviderUpdated && <label className="font-semibold">Provider Updated: </label>}
                  <label className="font-semibold">Created Date: </label>
                  <label className="font-semibold">Active Carrier Exclusion: </label>
                </div>
                <div className="flex flex-col items-end">
                  <span>{client.isActive ? 'Enabled' : 'Disabled'}</span>
                  <span>{format(new Date(`${client.providerUpdatedAt}`), 'MM/dd h:mm a')}</span>
                  <span>{format(new Date(`${client.createdAt}`), 'MM/dd h:mm a')}</span>

                  {/* TODO */}
                  {/* <span>{client.hasExclusion ? 'Yes' : 'No'}</span> */}
                </div>
              </div>
            </div>
          </Card>
        ))}
        {/* Pagination */}
        <Pagination
          count={totalCount || 0}
          loading={loading}
          paginationConfig={tableOptions.pagination}
          onPaginate={handlePagination}
        />
      </ul>
    </>
  );
};

export default Clients;
